import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import { enter, leave } from '../utils/transition-toggle'

// Connects to data-controller="mobile-nav"
export default class extends Controller {

  static targets = ["nav"]

  navActive = false

  connect() {
    useClickOutside(this, { element: this.navTarget })
  }

  toggle(event) {
    if (!this.navActive) {
      enter(this.navTarget)
    } else {
      leave(this.navTarget)
    }

    this.navActive = !this.navActive

    if (event) {
      event.stopImmediatePropagation()
      event.preventDefault() // so we don't end up with a # in the address bar
    }
    return false
  }

  closeAndScroll(event) {
    this.toggle(event)

    const link = event.target.closest("a").href;
    const anchor = link.split("#")[1]
    const el = document.querySelector(`#${anchor}`)

    el.scrollIntoView({ behavior: 'smooth' })
  }

  clickOutside() {
    if (this.navActive) this.toggle()
  }

  escClose(event) {
    if (this.navActive) {
      if (event.key === 'Escape') this.toggle()
    }
  }

}
