import "@hotwired/turbo"

import { application } from "./controllers/application"

import MobileNavController from "./controllers/mobile_nav_controller.js"
application.register("mobile-nav", MobileNavController)

document.addEventListener("turbo:load", () => {
  document.querySelectorAll('a[href*="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      const anchor = this.getAttribute("href").match(/(#[A-z0-9_\-]+)/)[1]
      const target = document.querySelector(anchor)

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth'
        });
        e.preventDefault();
      }
    });
  });
})
